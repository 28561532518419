/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */

@import 'variable';

@import 'spinner';
@import 'app';
@import 'header';
@import 'pages';
@import 'sidebar';
@import 'responsive';

@import "../styles/icons/material-design-iconic-font/css/materialdesignicons.min.css";

.reports-search-bar {
    width: 20%;
}

.report-not-found {
    height: 450px;
    text-align: center;
    margin-top: 20%
}

.report-not-found img {
    width: 100px;
}

.dropdown {
    .mat-select-arrow {
      border: none;
    }
  }
